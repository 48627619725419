<template>
  <div class="no">
    <svg
      t="1587378246185"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="1213"
      width="200"
      height="200"
    ><path
      d="M973.456873 586.376931v79.975195c31.990078-61.5809 49.584621-128.760063 49.584621-199.937987C1023.841245 208.09426 794.312437 0.158755 512 0.158755S0.158755 208.09426 0.158755 465.614387v9.597023l183.942947-207.935506h106.367009L132.917578 486.407938H184.101702v-55.982637l109.566017-155.151877v395.077461H184.101702V586.376931H17.753297a492.647199 492.647199 0 0 0 367.885896 331.097306c-11.996279 26.391814-45.585861 89.572218-100.768746 100.768745-68.778667 14.395535 186.342203 3.99876 369.485399-104.767505a503.843726 503.843726 0 0 0 317.501523-243.924344H863.890856V586.376931H679.947909V476.011162l183.942947-207.935506h103.967753l-155.151877 217.53253h51.184124v-59.981396l109.566017-155.151878v315.902019zM348.050851 607.970234a83.973954 83.973954 0 0 1-7.997519-35.988838v-215.933025c0-13.595783 2.399256-25.592062 7.997519-36.78859a93.570978 93.570978 0 0 1 21.593303-29.590822 103.967753 103.967753 0 0 1 71.977675-26.391814h90.37197a108.766265 108.766265 0 0 1 71.977675 26.391814L505.601984 420.828278v-23.992558a15.995039 15.995039 0 0 0-5.598263-11.196527c-3.199008-3.199008-7.997519-4.798512-12.796031-4.798512-5.598264 0-9.597023 1.599504-13.595783 4.798512a15.995039 15.995039 0 0 0-4.798512 11.996279v71.977675l-113.564776 151.95287a84.773706 84.773706 0 0 1-7.99752-12.796032z m285.511445-35.988838c0 12.796031-2.399256 23.992558-7.99752 35.988838a93.570978 93.570978 0 0 1-21.593302 29.590822 103.967753 103.967753 0 0 1-71.977675 27.191566H441.621829a108.766265 108.766265 0 0 1-71.977675-27.191566 99.968993 99.968993 0 0 1-11.196528-11.99628L468.013643 480.009922v51.184125c0 4.798512 1.599504 8.797271 5.598264 11.996279 3.199008 3.199008 7.997519 4.798512 12.796031 4.798512 5.598264 0 9.597023-1.599504 13.595783-4.798512a15.995039 15.995039 0 0 0 5.598263-11.996279V432.024805L607.970234 296.066975a83.973954 83.973954 0 0 1 25.592062 61.580899v215.933026z"
      fill="#1296db"
      p-id="1214"
    /></svg>
    <div class="text__info">
      請檢查您輸入的網址是否正確
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.no {
  width: 30rem;
  position: relative;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  text-align: center;
  .text__info {
    margin-top: 3rem;
    font-size: 1.8rem;
    font-weight: 500;
    color: #333333;
  }

}
</style>
